<template>
  <div class="brand-data-panel">
    <div class="card-col">
      <div class="body-card">
        <div class="body-card-inner">
          <span class="data-value">{{bodyData.height}}<span class="lt">cm</span></span>
          <span class="data-title">身高</span>
        </div>
      </div>
    </div>

    <div class="card-col">
      <div class="body-card">
        <div class="body-card-inner">
          <span class="data-value">{{bodyData.weight}}<span class="lt">kg</span></span>
          <span class="data-title">體重</span>
        </div>
      </div>
    </div>

    <div class="card-col">
      <div class="body-card">
        <div class="body-card-inner">
          <span class="data-value">{{bodyData.chestSize}}{{bodyData.opp}}</span>
          <span class="data-title">胸圍</span>
        </div>
      </div>
    </div>

    <div class="card-col">
      <div class="body-card">
        <div class="body-card-inner">
          <span class="data-value">{{bodyData.shoulderSize}}<span class="lt">cm</span></span>
          <span class="data-title">肩寬</span>
        </div>
      </div>
    </div>

    <div class="card-col">
      <div class="body-card">
        <div class="body-card-inner">
          <span class="data-value">{{bodyData.waistSize}}<span class="lt">cm</span></span>
          <span class="data-title">腰圍</span>
        </div>
      </div>
    </div>

    <div class="card-col">
      <div class="body-card">
        <div class="body-card-inner">
          <span class="data-value">{{bodyData.buttockSize}}<span class="lt">cm</span></span>
          <span class="data-title">臀圍</span>
        </div>
      </div>
    </div>

    <div class="card-col">
      <div class="body-card">
        <div class="body-card-inner">
          <span class="data-value">{{bodyData.feetSize}}<span class="lt">cm</span></span>
          <span class="data-title">腳長</span>
        </div>
      </div>
    </div>

    <div class="card-col">
      <div class="body-card">
        <div class="body-card-inner">
          <span class="data-value">{{bodyData.headSize}}<span class="lt">cm</span></span>
          <span class="data-title">頭圍</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'brand-size-container',
  data() {
    return {
    }
  },
  components: {
  },
  created() {
  },
  computed: {
    bodyData() {
      return this.value;
    }
  },
  mounted() {
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  watch: {
  },
  methods: {
    parseInput(ary) {
      this.tags.splice(0, this.tags.length);
      for (const t of ary) {
        this.tags.push(t);
      }
    },
    tagDeleted(tag) {
      for (let i=0;i<this.tags.length;i++) {
        if (this.tags[i].serial === tag.serial) {
          this.tags.splice(i, 1);
          return;
        }
      }
    },
  }

}
</script>

<style scoped>
  .brand-data-panel {
    background-color: #EEEEEE;
    border-radius: .5rem;
    padding: 1rem .5rem .5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .card-col {
    position: relative;
    flex: 0 0 25%;
    width: 25%;
    padding: 0 .5rem;
  }

  .body-card {
    position: relative;
    width: 100%;
    padding-top: 100%;
    background: #FFFFFF;
    border-radius: 12px;
    margin-bottom: .5rem;
  }

  .body-card-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .lt {
    font-size: 12px;
  }

  .data-value {
    font-size: 1rem;
    color: var(--main-green);
  }

  .data-title {
  }
</style>

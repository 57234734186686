<template>
  <div class="simple-cloth-analytics-panel">
    <div class="panel-card cloth-sum">
      <p class="panel-title">衣物<br/>總數量</p>
      <div class="py-0">
        <p class="panel-value">{{totalCount}}</p>
      </div>
      <p class="panel-amount">NTD</p>
      <p class="panel-amount">{{totalValue}}</p>
    </div>

    <div class="panel-card cloth-month">
      <p class="panel-title">本月<br/>新增單品數</p>
      <div class="py-0">
        <p class="panel-value">{{clothThisMonthCount}}</p>
      </div>
      <p class="panel-amount">NTD</p>
      <p class="panel-amount">{{clothThisMonthValue}}</p>
    </div>

    <div class="color-panel">
      <div class="color-row" v-for="(item) in colorDistribute" :key="item.color">
        <div class="color-circle">
          <ColorCircle :id="item.color" :size="14" active></ColorCircle>
        </div>
        <div class="color-count">{{item.count}}件</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import ColorCircle from '@/components/closet/ColorCircle.vue';

export default {
  name: 'simple-cloth-analytics-panel',
  data() {
    return {
    }
  },
  components: {
    ColorCircle,
  },
  created() {
  },
  computed: {
    ...mapState(['cloths']),
    inUseCloths() {
      const res = this.cloths.filter((cloth) => {
        return !cloth.isDeleted && cloth.recycleType === -1;
      });
      return res;
    },
    totalCount() {
      return this.inUseCloths.length;
    },
    totalValue() {
      const res = this.inUseCloths.reduce((sum, cloth) => {
        if (cloth.price > 0) {
          sum = sum + cloth.price;
        }
        return sum;
      }, 0);
      return res;
    },
    clothThisMonth() {
      const now = moment().format('YYYY-MM');
      const res = this.inUseCloths.filter((cloth) => {
        const m = moment(cloth.create_at).format('YYYY-MM');
        return m === now;
      });
      return res;
    },
    clothThisMonthCount() {
      return this.clothThisMonth.length;
    },
    clothThisMonthValue() {
      const res = this.clothThisMonth.reduce((sum, cloth) => {
        if (cloth.price > 0) {
          sum = sum + cloth.price;
        }
        return sum;
      }, 0);
      return res;
    },
    colorDistribute() {
      const res = this.inUseCloths.reduce((ary, cloth) => {
        for (let i=0;i<ary.length;i++) {
          if (ary[i].color === cloth.color) {
            ary[i].count += 1;
            return ary;
          }
        }
        ary.push({
          color: cloth.color,
          count: 1,
        })
        return ary;
      }, []);
      let sorted = res.sort((a, b) => {return b.count - a.count});
      if (sorted.length > 6) {
        sorted = sorted.splice(0, 6);
      }
      return sorted;
    },
  },
  mounted() {
  },
  props: {
  },
  watch: {
  },
  methods: {

  }

}
</script>

<style scoped>
  p {
    margin: 0;
  }

  .simple-cloth-analytics-panel {
    background-color: #EEEEEE;
    border-radius: .5rem;
    padding: 1rem;
    display: grid;
    column-gap: .5rem;
    row-gap: .5rem;
    grid-template-columns: repeat(3, 1fr);
    /* grid: auto / auto auto auto; */
  }

  .panel-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: .5rem;
    padding: 14px 0;
  }

  .cloth-sum {
    background-color: var(--main-green);
  }

  .cloth-month {
    background-color: var(--main-yellow);
  }

  .panel-title {
    text-align: center;
    font-size: 13px;
    font-weight: 1000;
    color: #4D4D4D;
  }

  .panel-value {
    color: var(--main-orange);
    font-size: 20px;
    font-weight: 1000;
  }

  .panel-amount {
    color: #fff;
    font-size: 12px;
    line-height: 1;
  }

  .color-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: .5rem;
    padding: 8px;
    background-color: #fff;
    font-size: 12px;
  }

  .color-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>

<template>
  <div class="closet-data-simple">
    <div class="container">

      <div class="">
        <p class="title" @click="showBodyDialog">我的身體數據<svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.999998 12L7 6.5L1 0.999999" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round"/></svg></p>

        <div class="mb-2" @click="showBodyDialog">
          <BodyDataPanel :value="body"></BodyDataPanel>
        </div>
        <div class="">
          <BrandSizeContainer v-model="brandSizeList" @addClick="showBodyDialog" hide-delete show-add></BrandSizeContainer>
        </div>
      </div>

      <div class="">
        <p class="title">我的衣櫥數據<svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.999998 12L7 6.5L1 0.999999" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round"/></svg></p>

        <div class="">
          <SimpleClothAnalyticsPanel/>
        </div>
      </div>

    </div>

  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import BrandSizeContainer from '@/components/body/BrandSizeContainer.vue'
import BodyDataPanel from '@/components/body/BodyDataPanel.vue'
import SimpleClothAnalyticsPanel from '@/components/analytic/SimpleClothAnalyticsPanel.vue'

export default {
  name: 'ClosetDataSimple',
  data() {
    return {
      readingBusyName: 'READINGSNIPPESTDATA',

      body: {
        buttockSize: 0,
        chestSize: 0,
        feetSize: 0,
        headSize: 0,
        height: 0,
        opp: "",
        shoulderSize: 0,
        waistSize: 0,
        weight: 0,
      },

      brandSizeList: [],
    };
  },
  components: {
    BrandSizeContainer,
    BodyDataPanel,
    SimpleClothAnalyticsPanel,
  },
  props: {
  },
  beforeDestroy() {
    this.clearComponentBusy(this.readingBusyName);
    this.clearComponentBusy(this.savingBusyName);
  },
  async mounted() {
    await this.__refresh();
  },
  computed: {
    ...mapGetters(['isBodyConfigShow']),
  },
  watch: {
    isBodyConfigShow(val) {
      if (!val) {
        this.__refresh();
      }
    },
  },
  methods: {
    ...mapActions(['showMsgModal', 'setBodyConfigShow', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async __refresh() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        const userBody = await this.$store.dispatch('api/getUserBodyDataPromise');
        this.body = Object.assign({}, this.body, userBody);
        const sizeList = await this.$store.dispatch('api/readBrandSizeListPromise');
        this.brandSizeList.splice(0, this.brandSizeList.length);
        for (const s of sizeList) {
          this.brandSizeList.push(s);
        }
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    showBodyDialog() {
      this.setBodyConfigShow(true);
    },
  }
}
</script>

<style scoped>
  .closet-data-simple {
    padding: .5rem 0;
  }

  .title {
    position: relative;
    font-weight: 800;
    width: fit-content;
    letter-spacing: 0.13em;
    margin-top: 1rem;
    margin-bottom: .5rem;
  }

  .title>svg {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(1rem, -50%);
  }

</style>
